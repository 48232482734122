/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, TitleMain, Text, Button, Subtitle, Title, PriceWrap, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-1x6mahh --style1 bg--center --full" name={"einleitung"} fullscreen={true} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/i/template/36/img-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/36/img-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/36/img-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/36/img-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/36/img-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/36/img-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/36/img-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/36/img-2_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"paddingTop":0,"marginBottom":25,"paddingBottom":0}} columns={"1"}>
            
            <ColumnWrapper className="--style1 mt--0 pl--20" style={{"maxWidth":1000,"marginTop":null,"paddingTop":60,"paddingLeft":null,"marginBottom":0,"paddingBottom":0}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={null} svg={true} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":160,"marginTop":0,"paddingTop":0,"paddingLeft":null,"marginBottom":0,"paddingBottom":0}} srcSet={null} content={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\r\n\t viewBox=\"0 0 95.1 80\" style=\"enable-background:new 0 0 95.1 80;\" xml:space=\"preserve\">\r\n<style type=\"text/css\">\r\n\t.st0{fill:#FFFFFF;}\r\n</style>\r\n<path class=\"st0\" d=\"M64.4,25.5c-0.6,0-1.3,0-1.9,0.1C57.3,15,46.6,8.2,34.7,8.2c-17,0-30.9,13.9-30.9,30.9S17.7,70,34.7,70\r\n\tc3.3,0,6.5-0.5,9.6-1.5c5,6,12.3,9.4,20.1,9.4c14.4,0,26.2-11.8,26.2-26.2S78.8,25.5,64.4,25.5z M6.3,39c0-15.7,12.7-28.4,28.4-28.4\r\n\tc10.6,0,20.3,5.9,25.2,15.2c-2.2,0.4-4.4,1.1-6.4,2c-3.9-6.6-11-10.7-18.8-10.7C22.6,17.1,12.8,27,12.8,39s9.8,22,21.9,22\r\n\ts21.9-9.9,21.9-22c0-0.5,0-1-0.1-1.5c1.9-1.1,4-1.7,6.3-1.9c0.1,1.1,0.2,2.3,0.2,3.4c0,15.7-12.7,28.4-28.4,28.4S6.3,54.7,6.3,39z\r\n\t M51.3,29c-7.8,4.5-13.1,13-13.1,22.7c0,2.1,0.3,4.3,0.8,6.3c-1.4,0.3-2.8,0.5-4.2,0.5c-10.7,0-19.4-8.7-19.4-19.4\r\n\ts8.7-19.4,19.4-19.4C41.6,19.6,47.8,23.2,51.3,29z M48.3,51.7c0-5,2.3-9.5,5.9-12.4c-0.1,5.3-2.3,10.2-5.8,13.7\r\n\tC48.3,52.5,48.3,52.1,48.3,51.7z M65.4,35.6c8.4,0.5,15.1,7.5,15.1,16.1c0,8.9-7.2,16.1-16.1,16.1c-4.1,0-7.9-1.5-10.9-4.2\r\n\tC60.9,57.9,65.6,49,65.6,39C65.6,37.9,65.6,36.7,65.4,35.6z M64.4,75.4c-6.8,0-13.1-2.8-17.6-7.9c1.6-0.7,3.2-1.5,4.7-2.5\r\n\tc3.5,3.4,8.1,5.3,13,5.3c10.3,0,18.6-8.4,18.6-18.6S74.7,33,64.4,33s-18.6,8.4-18.6,18.6c0,1,0.1,2.1,0.3,3.1c-1.4,1-3,1.9-4.7,2.5\r\n\tc-0.4-1.8-0.7-3.7-0.7-5.6c0-13,10.6-23.6,23.7-23.6s23.7,10.6,23.7,23.7S77.5,75.4,64.4,75.4z\"/>\r\n</svg>\r\n"}>
              </Image>

              <TitleMain className="title-box fs--72 w--900 swpf--uppercase ls--12 lh--1" style={{"maxWidth":365,"marginTop":10,"paddingLeft":null,"marginBottom":0,"paddingRight":null,"paddingBottom":0}} content={"<span style='color: white'>Anna & Thomas</span>"}>
              </TitleMain>

              <Text className="text-box text-box--style1 fs--16 w--600 ls--04 lh--14" style={{"maxWidth":365,"marginTop":25,"paddingLeft":null,"marginBottom":0,"paddingRight":null,"paddingBottom":0}} content={"<span style='color: white'>Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen.</span>"}>
              </Text>

              <Button className="btn-box btn-box--style1 fs--16 w--900 swpf--uppercase ls--50" href={"#fotogallerie"} style={{"marginTop":23,"paddingLeft":null,"marginBottom":0,"paddingRight":null,"paddingBottom":0}} content={"Fotogalerie"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"information"} style={{"marginTop":0,"paddingTop":40,"paddingBottom":54}} layout={"l10"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"marginTop":0}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box ff--2 fs--48" style={{"maxWidth":450,"marginTop":0,"marginBottom":0}} content={"<span style=\"color: var(--color-supplementary)\">großartige Hochzeit</span>"}>
              </Text>

              <Text className="text-box fs--18" style={{"maxWidth":837,"marginTop":2}} content={"<span style=\"color: var(--color-custom-1);\">Die Zeremonie wird am 13.7.2019 in der Kirchte der Heiligen Marie stattfinden. Wir möchten uns bei Allen bedanken.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"fotogallerie"} style={{"paddingTop":null,"paddingBottom":null}} layout={"l12"}>
          
          <ColumnWrap className="column__flex --full --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/36/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/36/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/36/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/36/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/36/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/36/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/36/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/36/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/36/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/36/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/36/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/36/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/36/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/36/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/36/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/36/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/36/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/36/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/36/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/36/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/36/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/36/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/36/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/36/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/36/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/36/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/36/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/36/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/36/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/36/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/36/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/36/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/36/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/36/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/36/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/36/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"nachricht"} style={{"marginTop":0,"paddingTop":40,"paddingBottom":55}} layout={"l10"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"marginTop":0}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box ff--2 fs--48" style={{"maxWidth":450,"marginTop":0,"marginBottom":0}} content={"<span style=\"color: var(--color-custom-1);\">Eure schönen Wünsche</span><br>"}>
              </Text>

              <Text className="text-box fs--18" style={{"maxWidth":840,"marginTop":2}} content={"Wir möchten uns bei allen bedanken. Sie können uns im Formular einen Wunsch hinterlassen."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"nachricht-2"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex el--3" style={{"maxWidth":1300,"marginTop":0}} columns={"3"}>
            
            <ColumnWrapper className="pb--25 pl--16 pr--16 pt--16" style={{"maxWidth":"","paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null,"backgroundColor":"var(--color-dominant)"}}>
              
              <Text className="text-box fs--18" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-supplementary) font-style: italic;\">\"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen.\"</span>"}>
              </Text>

              <Text className="text-box fs--18" content={"<span>—   Jana Müller</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--25 pl--16 pr--16 pt--16" style={{"maxWidth":"","paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null,"backgroundColor":"var(--color-dominant)"}}>
              
              <Text className="text-box fs--18" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-supplementary) font-style: italic;\">\"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen.\"</span>"}>
              </Text>

              <Text className="text-box fs--18" content={"<span>—   Jana Müller</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--25 pl--16 pr--16 pt--16" style={{"maxWidth":"","paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null,"backgroundColor":"var(--color-dominant)"}}>
              
              <Text className="text-box fs--18" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-supplementary) font-style: italic;\">\"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen.\"</span>"}>
              </Text>

              <Text className="text-box fs--18" content={"<span>—   Jana Müller</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--25 pl--16 pr--16 pt--16" style={{"maxWidth":"","paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null,"backgroundColor":"var(--color-dominant)"}}>
              
              <Text className="text-box fs--18" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-supplementary) font-style: italic;\">\"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen.\"</span>"}>
              </Text>

              <Text className="text-box fs--18" content={"<span>—   Jana Müller</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--25 pl--16 pr--16 pt--16" style={{"maxWidth":"","paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null,"backgroundColor":"var(--color-dominant)"}}>
              
              <Text className="text-box fs--18" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-supplementary) font-style: italic;\">\"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen.\"</span>"}>
              </Text>

              <Text className="text-box fs--18" content={"<span>—   Jana Müller</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pb--25 pl--16 pr--16 pt--16" style={{"maxWidth":"","paddingTop":null,"paddingLeft":null,"paddingRight":null,"paddingBottom":null,"backgroundColor":"var(--color-dominant)"}}>
              
              <Text className="text-box fs--18" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-supplementary) font-style: italic;\">\"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen.\"</span>"}>
              </Text>

              <Text className="text-box fs--18" content={"<span>—   Jana Müller</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--25" name={"nachricht-3"} layout={"l24"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--right fs--26 w--600 ls--001 mt--0" content={"Eure Wünsche freuen uns!"}>
              </Subtitle>

              <Button className="btn-box btn-box--left swpf--uppercase mt--0" href={"#kontakt"} content={"Nachricht hinterlassen"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"programm"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --full --left el--4 pl--40 pr--40" style={{"paddingLeft":null,"paddingRight":null}} columns={"4"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 fs--20 ls--02" content={"14:00"}>
              </Title>

              <Title className="title-box fs--36 ls--002" style={{"marginTop":0}} content={"Treffpunkt vor der Kirche"}>
              </Title>

              <Text className="text-box fs--18 w--600" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 fs--20 ls--02" content={"15:00"}>
              </Title>

              <Title className="title-box fs--36 ls--002" style={{"marginTop":0}} content={"Anfang der Zeremonie"}>
              </Title>

              <Text className="text-box fs--18 w--600" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 fs--20 ls--02" content={"16:00"}>
              </Title>

              <Title className="title-box fs--36 ls--002" style={{"marginTop":0}} content={"Kuchen schneiden"}>
              </Title>

              <Text className="text-box fs--18 w--600" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 fs--20 ls--02" content={"17:00"}>
              </Title>

              <Title className="title-box fs--36 ls--002" style={{"marginTop":0}} content={"Tanz"}>
              </Title>

              <Text className="text-box fs--18 w--600" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 fs--20 ls--02" content={"18:00"}>
              </Title>

              <Title className="title-box fs--36 ls--002" style={{"marginTop":0}} content={"Anfang der Zeremonie"}>
              </Title>

              <Text className="text-box fs--18 w--600" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--20 ls--02" content={"19:00"}>
              </Title>

              <Title className="title-box title-box--style3 ff--2 fs--36 ls--001" style={{"marginTop":10}} content={"Überraschung"}>
              </Title>

              <Text className="text-box fs--18 w--600" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 fs--20 ls--02" content={"20:00"}>
              </Title>

              <Title className="title-box fs--36 ls--002" style={{"marginTop":0}} content={"Anfang der Zeremonie"}>
              </Title>

              <Text className="text-box fs--18 w--600" content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Einige Elemente enthalten bestimmte Funktionen."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"artikel"} style={{"marginTop":0,"paddingTop":40,"paddingBottom":40,"backgroundColor":"var(--color-blend--95)"}} layout={"l10"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"marginTop":0}} columns={"1"}>
            
            <ColumnWrapper style={{"paddingBottom":0}}>
              
              <Text className="text-box ff--2 fs--48" style={{"maxWidth":450,"marginTop":0,"marginBottom":0}} content={"<span style=\"color: var(--color-supplementary)\">Hochzeitsgeschenke</span>"}>
              </Text>

              <Text className="text-box fs--18" style={{"maxWidth":840,"marginTop":2,"marginBottom":0}} content={"<span style=\"color:var(--color-custom-1);\">Geschenke sind wirklich nicht notwendig. Zunächst möchten wir, dass Sie kommen! Wenn Sie jedoch einen Geschenk schenken wollen, können Sie etwas aus der Liste auswählen..</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--20" name={"artikel-2"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <PriceWrap style={{"backgroundColor":"var(--color-dominant)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span>Porzellanteller-Set</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box fs--18" content={"Wir haben nocht nicht"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"var(--color-dominant)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span>Porzellanteller-Set</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box fs--18" content={"Wir haben nocht nicht"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"var(--color-dominant)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span>Porzellanteller-Set</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box fs--18" content={"Wir haben nocht nicht"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"var(--color-custom-1)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style='color: white'>Porzellanteller-Set</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box fs--18" content={"<span style='color: white'>Schon reserviert, Danke!</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"var(--color-dominant)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span>Porzellanteller-Set</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box fs--18" content={"Wir haben nocht nicht"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap style={{"backgroundColor":"var(--color-custom-1)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18 text-box--invert" content={"<span style='color: white'>Porzellanteller-Set</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box fs--18 text-box--invert" content={"<span style='color: white'>Schon reserviert, Danke!</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"fotogallerie-2"} style={{"marginTop":0,"paddingTop":40,"paddingBottom":12,"backgroundColor":"var(--color-dominant)"}} layout={"l10"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"marginTop":0}} columns={"1"}>
            
            <ColumnWrapper style={{"paddingBottom":0}}>
              
              <Text className="text-box ff--2 fs--48" style={{"maxWidth":570,"marginTop":0,"marginBottom":0}} content={"<span style=\"color: var(--color-supplementary)\">Fotogalerie</span>"}>
              </Text>

              <Text className="text-box fs--18" style={{"maxWidth":840,"marginTop":2,"marginBottom":0}} content={"<span style=\"color: var(--color-custom-1);\">Suchen Sie sich auf den Bildern.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--l12w pb--30 pt--30" name={"fotogallerie-3"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"marginBottom":0,"paddingBottom":0}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/36/img-1_s=350x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/36/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/36/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/36/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/36/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/36/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/36/img-2_s=350x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/36/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/36/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/36/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/36/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/36/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/36/img-3_s=350x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/36/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/36/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/36/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/36/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/36/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/36/img-2_s=350x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/36/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/36/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/36/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/36/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/36/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/36/img-3_s=350x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/36/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/36/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/36/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/36/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/36/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/36/img-1_s=350x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/36/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/36/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/36/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/36/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/36/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/36/img-1_s=350x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/36/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/36/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/36/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/36/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/36/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/36/img-2_s=350x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/36/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/36/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/36/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/36/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/36/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/36/img-3_s=350x_.jpg"} svg={false} ratio={"3:2"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/36/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/36/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/36/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/36/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/36/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--25 pt--25" name={"fotogallerie-4"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l24"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box subtitle-box--right fs--26 w--600 ls--001 mt--0" content={"<span style=\"color: var(--color-dominant)\">Alle 640 Fotos von der Hochzeit können Sie herunterladen</span>"}>
              </Subtitle>

              <Button className="btn-box btn-box--left swpf--uppercase mt--0" content={"Herunterladen (1,5GB)"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-q83drf bg--center" name={"kontakt"} style={{"marginTop":0,"paddingTop":90,"paddingBottom":100}} layout={"l10"} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/i/template/36/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/36/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/36/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/36/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/36/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/36/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/36/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/i/template/36/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --left el--1" style={{"marginTop":0}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box ff--2 fs--48" style={{"maxWidth":450,"marginTop":0,"marginBottom":0}} content={"<span style=\"color: white;\">Wir bedanken uns im Voraus!</span>"}>
              </Text>

              <Text className="text-box fs--18" style={{"maxWidth":480,"marginTop":2}} content={"<span style=\"color: white;\">Es freut uns, dass Sie den Tag mit uns verbracht haben. Sie können uns eine Nachricht hinterlassen :-)!</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"kontakt-2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Ganze Name","type":"text","required":true,"placeholder":"Bitte tragen Sie Ihren Namen ein"},{"name":"E-Mail Kontakt:","type":"email","required":true,"placeholder":"Bitte geben Sie Ihre e-Mail Adresse ein"},{"name":"Telefon Nr.","type":"text","placeholder":"Telefon Nr."},{"name":"Nachricht","type":"textarea","required":true,"placeholder":"Bitte geben Sie Text Ihrer Nachricht ein"},{"name":"Versenden","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"partei"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box fs--18" content={"Erstellt mit <a href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}